section.article-header {
	padding-top: 0;

	figure {
		margin-bottom: 0;

		figcaption {
			max-width: 75%;
			font-size: 1rem;
			margin-top: 1rem;

			@include media-breakpoint-up(lg) {
				max-width: 55%;
				font-size: 1.125rem;
			}
		}
	}
}
