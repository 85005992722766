.accordion {
	.accordion-button {
		font-size: inherit;
		font-weight: $font-weight-bold;

		@include media-breakpoint-up(xl) {
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
		}
	}
	.accordion-header {
		margin-bottom: 0 !important;
	}
}
