section.article-body {
	p.article-meta {
		margin: 2rem 0;

		@include media-breakpoint-up(lg) {
			margin: 2.5rem 0;
		}
	}

	.article-content {
		line-height: 1.875;

		p, h1, h2, h3, h4, h5, h6 {
			font-size: 100%;
			margin-bottom: 1em;
			margin-top: 1.5em;

			&:first-child {
				margin-top: 0;
			}
		}

		a {
			text-decoration: underline;
		}

		p + p {
			margin-top: 0;
		}

		blockquote {
			text-align: center;
			font-size: 1.5rem;
			line-height: 1.5;
			margin: 3rem 0;
			font-style: italic;

			@include media-breakpoint-up(md) {
				font-size: 2rem;
				margin: 4rem 0;
				padding: 0 2rem;
			}
			@include media-breakpoint-up(lg) {
				font-size: 2.25rem;
				margin: 5rem 0;
			}
			@include media-breakpoint-up(xl) {
				font-size: 2.5rem;
				margin: 6rem 0;
				padding: 0 2.5rem;
			}
		}

		> :last-child {
			margin-bottom: 0;
		}

		.accordion {
			.accordion-body {
				h2 {
					font-size: 1.5em;
				}

				h3 {
					font-size: 1.35em;
				}

				h4 {
					font-size: 1.2em;
				}
			}
		}

		p.file {
			font-weight: $font-weight-bold;

			a {
				text-decoration: underline $green;
			}
		}
	}

	&.first {
		.article-content {
			p:first-of-type {
				font-weight: $font-weight-bold;
				margin-bottom: 3rem;
			}
		}
	}

	.row-link {
		margin: 2rem 0;

		@include media-breakpoint-up(lg) {
			margin: 3rem 0;
		}
		@include media-breakpoint-up(xxl) {
			margin: 4rem 0;
		}
	}
}
