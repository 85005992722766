#header {
	background: $green;
	font-weight: $font-weight-bold;

	.container-fluid {
		@include media-breakpoint-up(lg) {
			height: 10rem;
			font-size: 1.25rem;
		}
		@include media-breakpoint-up(xxl) {
			height: 12rem;
			font-size: 1.5rem;
		}
	}

	.navbar-brand {
		img {
			max-width: 14.5rem;
			width: 26vw;
			min-width: 8rem;
		}
	}

	.nav-link {
		@include media-breakpoint-only(lg) {
			padding-left: 0.875rem;
			padding-right: 0.875rem;
		}
	}

	.nav-link.has-arrow {
		transition: $transition-base;

		&:hover {
			transform: translate(0.75rem);

			.arrow-right {
				opacity: 1;
			}
		}

		.arrow-right {
			transform: scaleX(-1);
			width: 16px;
			height: 16px;
			display: inline-block;
			vertical-align: middle;
			opacity: 0;
			transition: $transition-base;
			position: relative;
			top: -2px;
			margin-left: -16px;
		}
	}

	.search-icon {
		@extend .search-icon;
	}

	.flag-gb {
		display: inline-block;
		width: 1em;
		height: 1em;
		position: relative;
		top: 0.125rem;
		background: url(escape-svg('data:image/svg+xml,<svg class="icon-flag" xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-gb" viewBox="0 0 640 480"><path fill="%23012169" d="M0 0h640v480H0z"></path> <path fill="%23FFF" d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"></path> <path fill="%23C8102E" d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"></path> <path fill="%23FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"></path> <path fill="%23C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"></path> </svg>')) 50% 50% no-repeat;
	}

	.flag-no {
		display: inline-block;
		width: 1em;
		height: 1em;
		position: relative;
		top: 0.125rem;
		background: url(escape-svg('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 16"><path fill="#ba0c2f" d="M0 0h22v16H0z"/><g fill="#fff"><path d="M6 0h4v16H6z"/><path d="M0 6h22v4H0z"/></g><g fill="#00205b"><path d="M7 0h2v16H7z"/><path d="M0 7h22v2H0z"/></g></svg>')) 50% 50% no-repeat;
	}
}

.search-icon {
	margin-left: 0.375rem;
	display: inline-block;
	vertical-align: baseline;
	width: 0.8em;
	height: 0.8em;
	position: relative;
	top: 0.05em;
	background: url(escape-svg('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.4 490.4" style="enable-background:new 0 0 490.4 490.4" xml:space="preserve"><path fill="#{$body-color}" d="m484.1 454.796-110.5-110.6c29.8-36.3 47.6-82.8 47.6-133.4 0-116.3-94.3-210.6-210.6-210.6S0 94.496 0 210.796s94.3 210.6 210.6 210.6c50.8 0 97.4-18 133.8-48l110.5 110.5c12.9 11.8 25 4.2 29.2 0 8.4-8.3 8.4-20.8 0-29.1zm-443-244c0-93.6 75.9-169.5 169.5-169.5s169.6 75.9 169.6 169.5-75.9 169.5-169.5 169.5-169.6-75.9-169.6-169.5z"/></svg>')) 50% 50% no-repeat;
}
