section.employees {
	.search {
		margin-bottom: 1.5rem;
	}
	.filter {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;

		.col {
			display: flex;
			flex-wrap: wrap;
			padding-left: calc(var(--bs-gutter-x) * 0.5 - 0.5rem);
		}
		a {
			padding: 0.25rem 0.625rem;
			border: 1px solid $green;
			margin: 0 0.5rem;
			cursor: pointer;

			&.selected {
				background: $green;
				color: $white;
			}
		}
	}

	.people {
		margin-bottom: -2rem;

		@include media-breakpoint-up(xl) {
			margin-bottom: -3rem;
		}
	}

	.person {
		margin-bottom: 2rem;

		@include media-breakpoint-up(xl) {
			margin-bottom: 3rem;
		}

		h2 {
			font-size: inherit;

			a {
				text-decoration: underline $green;
			}
		}
		.small {
			font-size: 0.875rem;

			@include media-breakpoint-up(lg) {
				font-size: 1rem;
			}

			a {
				font-weight: $font-weight-bold;
				text-decoration: underline $green;
			}
		}
	}
}
