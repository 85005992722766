section.offers {
	.offer-cta {
		@include media-breakpoint-up(xl) {
			margin: 1.5rem 0;
		}
	}
	p {
		margin-bottom: 0.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
