.container-fluid {
	@include media-breakpoint-up(xl) {
		--bs-gutter-x: 2rem;
		--container-gutter-x: 2rem;
	}
	@include media-breakpoint-up(xxl) {
		--bs-gutter-x: 4rem;
		--container-gutter-x: 4rem;
	}
	@include media-breakpoint-up(xxxl) {
		--bs-gutter-x: 6.25rem;
		--container-gutter-x: 6.25rem;
	}
}

section {
	--section-padding: 3rem;
	padding: var(--section-padding) 0;

	@include media-breakpoint-up(md) {
		--section-padding: 3.5rem;
	}

	@include media-breakpoint-up(xxl) {
		--section-padding: 4.5rem;
	}

	&.bg-green + &.bg-green,
	&.bg-white + &.bg-white,
	&.bg-green-white + &.bg-white {
		padding-top: 0;
	}

	&.bg-image {
		--section-padding: 0;
	}
}

.bg-green {
	background: $green;
}

.bg-white {
	background: $white;
}

.bg-green-white {
	background: linear-gradient(0deg, $white 50%, $green 50%);
}
