section.news-carousel {
	.container-fluid {
		padding-right: 0;
	}
	.carousel {
		margin-top: 1rem;

		@include media-breakpoint-up(xl) {
			margin-top: 2rem;
		}
		@include media-breakpoint-up(xxxl) {
			margin-top: 3rem;
		}

		&:first-child {
			margin-top: 0;
		}
	}
	.news-item {
		padding-right: 2rem;

		@include media-breakpoint-up(md) {
			padding-right: 3rem;
		}
		@include media-breakpoint-up(lg) {
			padding-right: 5rem;
		}
		@include media-breakpoint-up(xl) {
			padding-right: 8rem;
		}
		@include media-breakpoint-up(xxl) {
			padding-right: 10rem;
		}
		@include media-breakpoint-up(xxxl) {
			padding-right: 13rem;
		}
	}
	.news-image {
		margin-bottom: 2rem;

		@include media-breakpoint-up(lg) {
			margin-bottom: 3rem;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 3.5rem;
		}
		@include media-breakpoint-up(xxl) {
			margin-bottom: 4rem;
		}

		.placeholder-wrapper {
			position: relative;
			cursor: pointer;

			.play-button {
				display: block;
				width: 6rem;
				height: 6rem;
				position: absolute;
				top: 50%;
				left: 50%;
				background: url(escape-svg('data:image/svg+xml,<svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="75" cy="75" r="75" fill="#{$green}"/><path d="M119 75.5L52.25 114.038L52.25 36.9619L119 75.5Z" fill="#{$body-color}"/></svg>')) 50% 50% / contain no-repeat;
				transform: translate(-50%, -50%);

				@include media-breakpoint-up(xxl) {
					width: 9rem;
					height: 9rem;
				}
			}
		}
	}
	.quote {
		@include media-breakpoint-up(sm) {
			font-size: 1.5rem;
		}
	}
	.quote-author {
		margin-top: 1.5rem;

		@include media-breakpoint-up(xl) {
			margin-top: 2.5rem;
		}
	}
	.news-title {
		font-size: 1.5rem;
		min-height: 3rem;
		margin-bottom: 0.5rem;

		@include media-breakpoint-up(md) {
			font-size: 1.75rem;
			min-height: 3.5rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 2rem;
			min-height: 4rem;
		}
	}
	.news-date {
		font-size: 1rem;
		margin-bottom: 1rem;

		@include media-breakpoint-up(lg) {
			font-size: 1.5rem;
			margin-bottom: 1.5rem;
		}
	}

	&.more-items {
		.news-item {
			padding-right: 1rem;

			@include media-breakpoint-up(md) {
				padding-right: 2rem;
			}
			@include media-breakpoint-up(lg) {
				padding-right: 3.5rem;
			}
			@include media-breakpoint-up(xl) {
				padding-right: 5rem;
			}
			@include media-breakpoint-up(xxl) {
				padding-right: 7rem;
			}
			@include media-breakpoint-up(xxxl) {
				padding-right: 8.5rem;
			}
		}
	}
}
