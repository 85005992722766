.list-group {
	line-height: 1.5;

	h1, h2, h3, h4, h5, h6 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.list-group-item {
		display: block;
		padding: 1.5rem 0 1.25rem;
		text-decoration: none !important;

		&:nth-child(even) {
			background: $green-light;
		}
	}

	.list-group-item-studies {
		border-bottom: none !important;
		@include media-breakpoint-up(xl) {
			--bs-gutter-x: 2rem;
			--container-gutter-x: 2rem;
		}
		@include media-breakpoint-up(xxl) {
			--bs-gutter-x: 4rem;
			--container-gutter-x: 4rem;
		}
		@include media-breakpoint-up(xxxl) {
			--bs-gutter-x: 6.25rem;
			--container-gutter-x: 6.25rem;
		}
		width: 100%;
		padding-right: var(--bs-gutter-x,.75rem);
		padding-left: var(--bs-gutter-x,.75rem);
		margin-right: auto;
		margin-left: auto;
	}
	.striped-background{
		background-color:#f6faed;
	}
}
