@font-face {
	font-family: "sofia-pro";
	src: url("../../fonts/sofia-pro/400.woff2") format("woff2"),
		 url("../../fonts/sofia-pro/400.woff") format("woff");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "sofia-pro";
	src: url("../../fonts/sofia-pro/400-italic.woff2") format("woff2"),
	url("../../fonts/sofia-pro/400-italic.woff") format("woff");
	font-display: auto;
	font-style: italic;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "sofia-pro";
	src: url("../../fonts/sofia-pro/600.woff2") format("woff2"),
		 url("../../fonts/sofia-pro/600.woff") format("woff");
	font-display: auto;
	font-style: normal;
	font-weight: 600;
	font-stretch: normal;
}
