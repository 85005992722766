#searchModal {

	img.logo {
		max-width: 14.5rem;
		width: 26vw;
		min-width: 8rem;
	}

	.search-wrapper {
		margin-top: 2rem;

		@include media-breakpoint-up(xl) {
			margin-top: 3rem;
		}
	}
}

.search-wrapper {
	position: relative;

	.search-icon {
		position: absolute;
		top: 0;
		left: $input-padding-y-lg;
		height: $input-height-lg;
		margin-top: -2px;
	}

	input {
		padding-left: calc(#{$input-padding-y-lg} + 2rem);

		&:focus {
			outline: 0;
			box-shadow: 0 0 0 0.25rem rgba($body-color, 0.5);
		}
	}
}
