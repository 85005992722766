section.hero {

	h1 {
		margin-bottom: 0;

		@include media-breakpoint-up(lg) {
			max-width: 90%;
		}
	}

	h2 {
		margin: 2rem 0;

		@include media-breakpoint-up(lg) {
			margin: 2.5rem 0;
		}
		@include media-breakpoint-up(xl) {
			margin: 3.25rem 0;
		}
	}

	.row-link {
		margin-bottom: 1rem;

		@include media-breakpoint-up(xl) {
			margin-bottom: 1.5rem;
		}
	}

	.row-mobile-img {
		margin-top: 2rem;
	}

	figure {
		margin-bottom: 0;

		figcaption {
			margin: 1rem var(--container-gutter-x, 0) 0 auto;
			font-size: 1rem;

			@include media-breakpoint-up(lg) {
				font-size: 1.125rem;
				margin-top: 1.5rem;
			}
		}
	}

	.col-image {
		position: relative;

		.image-placeholder {
			position: absolute;
			right: calc(-1 * var(--container-gutter-x) + var(--bs-gutter-x) * 0.5);
			top: calc(-1 * var(--section-padding));
			width: 100%;
			height: calc(100% + 3 * var(--section-padding) + 2.5rem * 1.2 + 1rem + 1.875rem + 2 * 1.25rem);
			background: var(--bg) 50% 50% / cover;

			@include media-breakpoint-up(lg) {
				height: calc(100% + 3 * var(--section-padding) + 2.5rem * 1.2 + 1rem + 1.875rem + 2 * 1.25rem);
			}
			@include media-breakpoint-up(xl) {
				height: calc(100% + 3 * var(--section-padding) + 3rem * 1.2 + 1.5rem + 2.25rem + 2 * 1.25rem);
			}
		}

		.image-placeholder-wrapper {
			position: absolute;
			right: calc(-1 * var(--container-gutter-x) + var(--bs-gutter-x) * 0.5);
			top: calc(-1 * var(--section-padding));
			width: 100%;
			height: 100%;

			figure {
				height: 100%;

				figcaption {
					@include media-breakpoint-up(xl) {
						width: 70%;
					}
				}
			}

			.image-placeholder {
				position: relative;
				right: 0;
				top: 0;
				background: var(--bg) 50% 50% / cover;
			}
		}

	}

	p.article-meta {
		margin: 2rem 0 0;

		@include media-breakpoint-up(lg) {
			margin-top: 2.5rem;
		}
	}

	a.search-study-place {
		text-align: center;
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border: 1px solid #000;
		font-weight: 600;
		transition: 0.3s all;

		&:hover {
			color: #a5cf4e;
			background-color: #000;
			border-color: #1e260f;
		}

		@include media-breakpoint-up(lg) {
			font-size: 1.5rem;
			padding: 1rem 1.5rem;
		}
	}
}

p.article-meta {
	font-size: 1rem;

	@include media-breakpoint-up(lg) {
		font-size: 1.125rem;
	}

	span {
		display: block;
		margin-bottom: 0.5rem;

		@include media-breakpoint-up(md) {
			margin-bottom: 0;
			display: inline-block;
			padding-right: 1rem;

			&:last-child {
				padding-right: 0;
			}
		}
	}
}
