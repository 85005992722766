.cta-btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: $font-weight-bold;
	background: $green;
	padding: 1rem 1.25rem;

	@include media-breakpoint-up(md) {
		padding: 1.25rem 1.5rem;
	}

	&::after {
		content: '';
		width: 2.25rem;
		height: 1.375rem;
		background: url(escape-svg('data:image/svg+xml,<svg width="38" height="24" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.332 0.844727L36.3098 11.8225L25.332 22.8003" stroke="black" stroke-width="3"/><path d="M36.3111 11.8223H0" stroke="black" stroke-width="3"/></svg>')) 50% 50% / cover no-repeat;
		position: relative;
		top: 1px;
		flex-shrink: 0;
		margin-left: 1rem;
	}

	&.dark {
		background: $body-color;
		color: $white;

		&::after {
			background: url(escape-svg('data:image/svg+xml,<svg width="38" height="24" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.332 0.844727L36.3098 11.8225L25.332 22.8003" stroke="#fff" stroke-width="3"/><path d="M36.3111 11.8223H0" stroke="#fff" stroke-width="3"/></svg>')) 50% 50% / cover no-repeat;
		}
	}

	&.cta-lg {
		margin-bottom: 2rem;
		font-size: calc(1.325rem + .9vw);
		padding: 1.25rem 1.5rem;
		line-height: $headings-line-height;

		@include media-breakpoint-up(md) {
			font-size: 2rem;
			padding: 2.5rem 3rem;
		}

		@include media-breakpoint-up(xl) {
			margin-bottom: 2.25rem;
			font-size: 2.5rem;
			padding: 2.75rem 3.5rem;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&::after {
			top: 3px;

			@include media-breakpoint-up(xl) {
				top: 4px;
			}
		}
	}
}
