body {
	@include media-breakpoint-up(lg) {
		font-size: 1.25rem;
	}
	@include media-breakpoint-up(xl) {
		font-size: 1.5rem;
	}
}

h1,
h2 {
	margin-bottom: 1rem;

	@include media-breakpoint-up(lg) {
		font-size: 2.5rem;
	}
	@include media-breakpoint-up(xl) {
		font-size: 3rem;
	}
}
