.arrow-link {
	display: inline-flex;
	align-items: center;
	text-decoration: underline;

	&::before {
		content: '';
		width: 2.25rem;
		height: 1.375rem;
		margin-right: 1.125rem;
		background: url(escape-svg('data:image/svg+xml,<svg width="38" height="24" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.332 0.844727L36.3098 11.8225L25.332 22.8003" stroke="black" stroke-width="3"/><path d="M36.3111 11.8223H0" stroke="black" stroke-width="3"/></svg>')) 50% 50% / cover no-repeat;
		position: relative;
		top: 1px;
	}
}
