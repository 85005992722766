#footer {
	background: $green;
	padding: 2rem 0;
	font-size: 1.125rem;
	line-height: 1.875;

	@include media-breakpoint-up(md) {
		padding: 3rem 0 2rem;
	}
	@include media-breakpoint-up(lg) {
		font-size: 1.25rem;
	}
	@include media-breakpoint-up(xl) {
		padding: 4rem 0 2.5rem;
	}
	@include media-breakpoint-up(xxl) {
		padding: 5rem 0 3rem;
		font-size: 1.5rem;
	}
	@include media-breakpoint-up(xxxl) {
		padding: 6rem 0 4rem;
	}

	.col-social {
		margin-bottom: 1rem;

		@include media-breakpoint-up(md) {
			margin-bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}
	}

	.footer-logo {
		max-width: min(14.5rem, 60vw);
		margin: 2rem 0;

		@include media-breakpoint-up(md) {
			margin: 0;
		}
	}

	.social {
		margin-bottom: 0;

		a {
			display: block;
			padding: 0.25rem;

			&::after {
				content: '';
				display: block;
				width: 1.25rem;
				height: 1.25rem;
			}

			&.linkedin::after {
				background: url(escape-svg('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve"><path style="fill:#{$body-color}" d="M119.18 164.715H16.396a8.258 8.258 0 0 0-8.258 8.258v330.204a8.256 8.256 0 0 0 8.258 8.258H119.18a8.256 8.256 0 0 0 8.258-8.258V172.973a8.258 8.258 0 0 0-8.258-8.258zM67.825.563C30.426.563 0 30.956 0 68.314c0 37.374 30.426 67.779 67.825 67.779 37.369 0 67.771-30.406 67.771-67.779C135.597 30.956 105.195.563 67.825.563zM380.621 156.508c-41.282 0-71.799 17.747-90.309 37.911v-21.446a8.258 8.258 0 0 0-8.258-8.258H183.62a8.258 8.258 0 0 0-8.258 8.258v330.204a8.256 8.256 0 0 0 8.258 8.258h102.56a8.256 8.256 0 0 0 8.258-8.258V339.803c0-55.053 14.954-76.501 53.331-76.501 41.796 0 45.117 34.383 45.117 79.334v160.543a8.256 8.256 0 0 0 8.258 8.258h102.598a8.256 8.256 0 0 0 8.258-8.258V322.056c0-81.862-15.609-165.548-131.379-165.548z"/></svg>')) 50% 50% / cover no-repeat;
			}
			&.facebook::after {
				background: url(escape-svg('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310" style="enable-background:new 0 0 310 310" xml:space="preserve"><path style="fill:#{$body-color}" d="M81.703 165.106h33.981V305a5 5 0 0 0 5 5H178.3a5 5 0 0 0 5-5V165.765h39.064a5 5 0 0 0 4.967-4.429l5.933-51.502a5 5 0 0 0-4.966-5.572h-44.996V71.978c0-9.732 5.24-14.667 15.576-14.667h29.42a5 5 0 0 0 5-5V5.037a5 5 0 0 0-5-5h-40.545A39.746 39.746 0 0 0 185.896 0c-7.035 0-31.488 1.381-50.804 19.151-21.402 19.692-18.427 43.27-17.716 47.358v37.752H81.703a5 5 0 0 0-5 5v50.844a5 5 0 0 0 5 5.001z"/></svg>')) 50% 50% / cover no-repeat;
			}
			&.twitter::after {
				background: url(escape-svg('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="33.88" height="33.88" style="enable-background:new 0 0 33.88 33.88" xml:space="preserve"><path style="fill:#{$body-color}" d="M30.414 10.031c.014.297.021.595.021.897 0 9.187-6.992 19.779-19.779 19.779A19.66 19.66 0 0 1-.001 27.584c.546.063 1.099.095 1.658.095 3.26 0 6.254-1.107 8.632-2.974a6.958 6.958 0 0 1-6.491-4.828c.424.082.858.125 1.308.125a6.89 6.89 0 0 0 1.83-.244 6.958 6.958 0 0 1-5.576-6.815v-.087c.939.521 2.01.833 3.15.869a6.95 6.95 0 0 1-3.091-5.787c0-1.274.343-2.467.94-3.495a19.733 19.733 0 0 0 14.327 7.263 7.056 7.056 0 0 1-.18-1.584 6.95 6.95 0 0 1 6.953-6.949 6.94 6.94 0 0 1 5.07 2.192 13.944 13.944 0 0 0 4.416-1.686 6.964 6.964 0 0 1-3.057 3.844 13.834 13.834 0 0 0 3.991-1.092 14.104 14.104 0 0 1-3.465 3.6z"/></svg>')) 50% 50% / cover no-repeat;
			}
			&.instagram::after {
				background: url(escape-svg('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.4 490.4" style="enable-background:new 0 0 490.4 490.4" xml:space="preserve"><path style="fill:#{$body-color}" d="M345.9 0H144.5C64.8 0 0 64.8 0 144.5v201.4c0 79.7 64.8 144.5 144.5 144.5h201.4c79.7 0 144.5-64.8 144.5-144.5V144.5C490.4 64.8 425.6 0 345.9 0zm112.3 345.9c0 61.9-50.4 112.3-112.3 112.3H144.5c-61.9 0-112.3-50.4-112.3-112.3V144.5c0-61.9 50.4-112.3 112.3-112.3h201.4c61.9 0 112.3 50.4 112.3 112.3v201.4z"/><path style="fill:#{$body-color}" d="M244.1 117.8c-71.5 0-129.7 58.2-129.7 129.7s58.2 129.7 129.7 129.7S373.7 319 373.7 247.5s-58.1-129.7-129.6-129.7zm0 227.2c-53.7 0-97.5-43.7-97.5-97.5s43.7-97.5 97.5-97.5 97.5 43.7 97.5 97.5-43.8 97.5-97.5 97.5zM381.7 95c-10.4 0-18.8 8.4-18.8 18.8s8.4 18.8 18.8 18.8 18.8-8.4 18.8-18.8S392.1 95 381.7 95z"/></svg>')) 50% 50% / cover no-repeat;
			}
		}
	}

	.address {
		text-decoration: underline;
	}

	.menu {
		padding: 0;
		margin: 0;

		li {
			padding: 0;
			margin: 0;
			list-style: none;
		}
	}

	.col-address, .col-menu {
		&:not(:last-child) {
			margin-bottom: 1rem;

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}
		}
	}
}
