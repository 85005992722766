.breadcrumb {
	font-size: 1rem;

	@include media-breakpoint-up(lg) {
		font-size: 1.125rem;
	}

	@include media-breakpoint-up(xl) {
		margin-bottom: 2rem;
	}
}
